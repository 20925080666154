<template>
  <div>
    <div class="row">
      <div class="col-3">
        <div class="col-sm-12">
          <!-- id added because Chrome was suggesting password and wouldn't listen to anything else -->
          <el-select
            id="job-search"
            filterable
            :filter-method="filterJobsSelect"
            class="select-primary full-width"
            placeholder="Choose job"
            v-model="select.jobId"
            @input="setJobIdSelection"
          >
            <el-option
              v-for="job in outreachJobs"
              class="select-primary"
              :value="job._id"
              :label="job.title"
              :key="job._id"
            >
              <span style="float: left">{{ job.title }}&nbsp;&nbsp;</span>
              <span style="float: right">{{ job.city }}, {{ job.state }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="col-2">
        <div class="col-sm-12">
          <el-select
            class="select-primary full-width"
            placeholder="Choose range (mi)"
            v-model="select.range"
            @input="setRangeSelection"
          >
            <el-option
              v-for="range in ranges"
              class="select-primary"
              :value="range.value"
              :label="range.label"
              :key="range.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="col-3">
        <div class="col-sm-12">
          <!-- id added because Chrome was suggesting password and wouldn't listen to anything else -->
          <el-select
            id="client-search"
            filterable
            :filter-method="filterClientsSelect"
            class="select-primary full-width"
            placeholder="Choose client"
            v-model="select.clientId"
            @input="setClientIdSelection"
          >
            <el-option
              v-for="client in outreachClients"
              class="select-primary"
              :value="client.id"
              :label="client.name"
              :key="client.id"
            >
              <span style="float: left">{{ client.name }}&nbsp;&nbsp;</span>
              <span style="float: right">{{ client.prismId }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="col-2">
        <div class="col-sm-12">
          <base-button
            type="default"
            @click="loadShifters()"
            :disabled="loadAndFilterDisabled"
            >Load Shifters</base-button
          >
        </div>
      </div>
      <div class="col-2">
        <div class="col-sm-12">
          <base-button
            type="default"
            @click="showCreateModal()"
            :disabled="createDisabled"
            >Create New Event</base-button
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="col-sm-12">
          <h4 class="mt-20 mb-5">Optional Filters</h4>
        </div>
      </div>
      <div class="col-3">
        <div class="col-sm-12">
          <label>Types</label>
          <el-select
            multiple
            :disabled="loadAndFilterDisabled"
            class="select-primary full-width"
            placeholder="Choose type(s)"
            v-model="select.type"
            @input="setTypeFilter"
          >
            <el-option
              v-for="type in types"
              class="select-primary"
              :value="type.value"
              :label="type.label"
              :key="type.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="col-3">
        <div class="col-sm-12">
          <label class="pr-20">Interested Positions</label>
          <base-switch
            v-model="switches.interestedPositionsDefaultOr"
            type="primary"
            on-text="OR"
            off-text="AND"
          />
          <el-select
            multiple
            :disabled="loadAndFilterDisabled"
            filterable
            :filter-method="filterInterestedPositionsSelect"
            class="select-primary full-width"
            placeholder="Choose position(s)"
            v-model="select.interestedPositions"
            @input="filterTable"
          >
            <el-option
              v-for="position in allInterestedPositions"
              class="select-primary"
              :value="position.value"
              :label="position.label"
              :key="position.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="col-3">
        <div class="col-sm-12">
          <label class="pr-20">Previous Positions</label>
          <base-switch
            v-model="switches.previousPositionsDefaultOr"
            type="primary"
            on-text="OR"
            off-text="AND"
          />
          <el-select
            multiple
            :disabled="loadAndFilterDisabled"
            filterable
            :filter-method="filterPreviousPositionsSelect"
            class="select-primary full-width"
            placeholder="Choose previous position(s)"
            v-model="select.previousPositions"
            @input="filterTable"
          >
            <el-option
              v-for="position in allPreviousPositions"
              class="select-primary"
              :value="position.value"
              :label="position.label"
              :key="position.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="col-3">
        <div class="col-sm-12">
          <label class="pr-20">Recent Positions</label>
          <base-switch
            v-model="switches.recentPositionsDefaultOr"
            type="primary"
            on-text="OR"
            off-text="AND"
          />
          <el-select
            multiple
            :disabled="loadAndFilterDisabled"
            filterable
            :filter-method="filterRecentPositionsSelect"
            class="select-primary full-width"
            placeholder="Choose recent position(s)"
            v-model="select.recentPositions"
            @input="filterTable"
          >
            <el-option
              v-for="position in allRecentPositions"
              class="select-primary"
              :value="position.value"
              :label="position.label"
              :key="position.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-responsive mt-20">
          <outreach-shifters-table
            :rowData="outreachShifters"
            :columns="tableOutreach.columns"
            @sort-action="sortAction"
          >
          </outreach-shifters-table>
        </div>
      </div>

      <modal
        :show.sync="modals.createModalVisible"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-lg"
        :centered="true"
        :show-close="true"
      >
        <card
          type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0"
        >
          <div class="text-muted text-left mb-3">
            <div>Create a new outreach event</div>
          </div>
          <form @submit.prevent>
            <div class="row">
              <label class="col-sm-2 col-form-label">Title</label>
              <div class="col-sm-10">
                <base-input v-model="eventForm.title" />
              </div>
              <label class="col-sm-2 col-form-label">Job</label>
              <div class="col-sm-6">
                <label>{{ eventModalDisplayFields.jobTitle }}</label>
              </div>
              <label class="col-sm-2 col-form-label">Range (mi)</label>
              <div class="col-sm-2">
                <label>{{ eventForm.range }}</label>
              </div>
              <label class="col-sm-2 col-form-label">Shifters</label>
              <div class="col-sm-10">
                <label>{{ eventModalDisplayFields.shifterNames }}</label>
              </div>
            </div>
            <div class="text-center">
              <base-button
                type="secondary"
                @click="modals.createModalVisible = false"
                >Close</base-button
              >
              &nbsp;
              <base-button
                native-type="submit"
                type="primary"
                class="my-4"
                @click="createEvent"
                >Save</base-button
              >
            </div>
          </form>
        </card>
      </modal>
    </div>
  </div>
</template>
<script>
import { Modal, OutreachShiftersTable, BaseSwitch } from '@/components';
import { Select, Option } from 'element-ui';

export default {
  components: {
    Modal,
    BaseSwitch,
    OutreachShiftersTable,
    [Select.name]: Select,
    [Option.name]: Option,
    //[Switch.name]: Switch,
  },
  mounted() {
    this.$store.dispatch('outreachShifters/loadJobs');
    this.$store.dispatch('outreachShifters/loadClients');
  },
  computed: {
    outreachShifters() {
      const filtersApplied =
        this.select.type?.length > 0 ||
        this.select.interestedPositions?.length > 0 ||
        this.select.previousPositions?.length > 0 ||
        this.select.recentPositions?.length > 0;

      return filtersApplied
        ? this.$store.getters['outreachShifters/filteredShifters']
        : this.$store.getters['outreachShifters/outreachShifters'];
    },
    outreachJobs() {
      return this.filteredJobs.length > 0
        ? this.filteredJobs
        : this.$store.getters['outreachShifters/outreachJobs'];
    },
    outreachClients() {
      return this.filteredClients.length > 0
        ? this.filteredClients
        : this.$store.getters['outreachShifters/outreachClients'];
    },
    allInterestedPositions() {
      return this.$store.getters['outreachShifters/interestedPositions'];
    },
    allRecentPositions() {
      return this.$store.getters['outreachShifters/recentPositions'];
    },
    allPreviousPositions() {
      return this.$store.getters['outreachShifters/previousPositions'];
    },
    loadAndFilterDisabled() {
      return !this.select.jobId || !this.select.range;
    },
    createDisabled() {
      return (
        !this.select.jobId ||
        !this.select.range ||
        !this.$store.getters['outreachShifters/selectedShifters'].length
      );
    },
  },
  methods: {
    sortAction(prop, order) {
      this.$store.dispatch('outreachShifters/setSortData', { prop, order });
      this.$store.dispatch('outreachShifters/sortTable');
    },
    setJobIdSelection(value) {
      this.$store.dispatch('outreachShifters/setJobIdSelection', value);
    },
    setClientIdSelection(value) {
      this.$store.dispatch('outreachShifters/setClientIdSelection', value);
    },
    setRangeSelection(value) {
      this.$store.dispatch('outreachShifters/setRangeSelection', value);
    },
    setTypeFilter(values) {
      this.typesFilterSelections = values;
      this.filterTable();
    },
    filterTable() {
      const shifters = this.$store.getters['outreachShifters/outreachShifters'];

      const filteredShifters = shifters.filter((shifter) => {
        const typeFilter = this.typesFilterSelections.length
          ? this.typesFilterSelections.includes(shifter.type)
          : true;

        let interestedPositionsFilter = true;
        if (this.select.interestedPositions.length) {
          switch (this.switches.interestedPositionsDefaultOr) {
            case true:
              // Match any (OR)
              interestedPositionsFilter = this.select.interestedPositions.some(
                (position) => shifter.interestedPositions.includes(position)
              );
              break;
            case false:
              // Match all (AND)
              interestedPositionsFilter = this.select.interestedPositions.every(
                (position) => shifter.interestedPositions.includes(position)
              );
              break;
          }
        }

        let previousPositionsFilter = true;
        if (this.select.previousPositions.length) {
          switch (this.switches.previousPositionsDefaultOr) {
            case true:
              // Match any (OR)
              previousPositionsFilter = this.select.previousPositions.some(
                (position) => shifter.previousPositions.includes(position)
              );
              break;
            case false:
              // Match all (AND)
              previousPositionsFilter = this.select.previousPositions.every(
                (position) => shifter.previousPositions.includes(position)
              );
              break;
          }
        }

        let recentPositionsFilter = true;
        if (this.select.recentPositions.length) {
          switch (this.switches.recentPositionsDefaultOr) {
            case true:
              // Match any (OR)
              recentPositionsFilter = this.select.recentPositions.some(
                (position) => shifter.recentPositions.includes(position)
              );
              break;
            case false:
              // Match all (AND)
              recentPositionsFilter = this.select.recentPositions.every(
                (position) => shifter.recentPositions.includes(position)
              );
              break;
          }
        }

        return (
          typeFilter &&
          interestedPositionsFilter &&
          previousPositionsFilter &&
          recentPositionsFilter
        );
      });

      this.$store.dispatch(
        'outreachShifters/setFilteredShifters',
        filteredShifters
      );
    },
    filterJobsSelect(query) {
      if (!query) {
        this.filteredJobs =
          this.$store.getters['outreachShifters/outreachJobs'];

        return;
      }

      this.filteredJobs = this.outreachJobs.filter(
        (job) =>
          job.title.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          job.city.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          job.state.toLowerCase().indexOf(query.toLowerCase()) > -1
      );
    },
    filterClientsSelect(query) {
      if (!query) {
        this.filteredClients =
          this.$store.getters['outreachShifters/outreachClients'];

        return;
      }

      this.filteredClients = this.outreachClients.filter(
        (client) =>
          client.name.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          client.prismId.toLowerCase().indexOf(query.toLowerCase()) > -1
      );
    },
    filterInterestedPositionsSelect(query) {
      if (!query) {
        this.filteredPositions =
          this.$store.getters['outreachShifters/interestedPositions'];

        return;
      }

      this.filteredPositions = this.interestedPositions.filter(
        (position) =>
          position.value.toLowerCase().indexOf(query.toLowerCase()) > -1
      );
    },
    filterRecentPositionsSelect(query) {
      if (!query) {
        this.filteredPositions =
          this.$store.getters['outreachShifters/recentPositions'];

        return;
      }

      this.filteredPositions = this.recentPositions.filter(
        (position) =>
          position.value.toLowerCase().indexOf(query.toLowerCase()) > -1
      );
    },
    filterPreviousPositionsSelect(query) {
      if (!query) {
        this.filteredPositions =
          this.$store.getters['outreachShifters/previousPositions'];

        return;
      }

      this.filteredPositions = this.previousPositions.filter(
        (position) =>
          position.value.toLowerCase().indexOf(query.toLowerCase()) > -1
      );
    },
    loadShifters() {
      this.$store.dispatch('outreachShifters/loadShifters');
    },
    formatPhoneColumn(row, column, cellValue, index) {
      return this.formatPhone(cellValue);
    },
    formatPhone(value) {
      var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      value = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');

      return value;
    },
    formatPositionsColumn(row, column, cellValue, index) {
      if (!cellValue) return '';

      // Split column data into array of positions and take first three
      const positions = cellValue.split(', ');
      switch (positions.length) {
        case 1:
          return positions[0];
        case 2:
          return positions.join(', ');
        default:
          return `${positions.slice(0, 3).join(', ')}...`;
      }
    },
    showCreateModal() {
      const jobId = this.$store.getters['outreachShifters/selectedJobId'];
      const jobTitle = this.$store.getters['outreachShifters/selectedJobTitle'];
      const range = this.$store.getters['outreachShifters/selectedRange'];

      const selectedShifters =
        this.$store.getters['outreachShifters/selectedShifters'];

      const shifterIds = selectedShifters.map((shifter) => shifter._id);
      const shifterNames = selectedShifters
        .map((shifter) => `${shifter.firstName} ${shifter.lastName}`)
        .join(', ');

      this.eventModalDisplayFields = {
        jobTitle,
        shifterNames,
      };

      this.eventForm = {
        title: '',
        job: jobId,
        range,
        shifters: shifterIds,
        status: 'inactive',
      };

      this.modals.createModalVisible = true;
    },
    createEvent() {
      this.$store.dispatch('outreachEvents/createEvent', this.eventForm);

      this.eventModalDisplayFields.jobTitle = '';
      this.eventForm.title = '';
      this.modals.createModalVisible = false;
    },
    // formatPhoneColumn(row, column, cellValue, index) {
    //   return this.formatPhone(cellValue);
    // },
    // formatPhone(value) {
    //   var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    //   value = !x[2]
    //     ? x[1]
    //     : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');

    //   return value;
    // },
  },
  data() {
    return {
      modals: {
        createModalVisible: false,
      },
      tableOutreach: {
        title: '',
        columns: [
          { name: 'First Name' },
          { name: 'Last Name' },
          { name: 'City', width: 125 },
          { name: 'State', width: 100 },
          { name: 'Zip', width: 100 },
          { name: 'Phone', formatter: this.formatPhoneColumn },
          { name: 'Type', width: 125 },
          { name: 'Pay Rate', width: 125 },
          { name: 'Max Distance' },
          { name: 'Week Hours', minWidth: 85 },
          // {
          //   name: 'Interested Positions',
          //   formatter: this.formatPositionsColumn,
          //   minWidth: 150,
          // },
          // {
          //   name: 'Previous Positions',
          //   formatter: this.formatPositionsColumn,
          //   minWidth: 150,
          // },
          // {
          //   name: 'Recent Positions',
          //   formatter: this.formatPositionsColumn,
          //   minWidth: 150,
          // },
          //{ name: 'Actions' },
        ],
      },
      ranges: [
        { value: 5, label: '5' },
        { value: 10, label: '10' },
        { value: 25, label: '25' },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
        { value: 250, label: '250' },
        { value: 500, label: '500' },
        //{ value: -1, label: 'Any' },
      ],
      types: [
        { value: 'Active', label: 'Active' },
        { value: 'Available', label: 'Available' },
        { value: 'Bench', label: 'Bench' },
      ],
      filteredJobs: [],
      filteredClients: [],
      filteredInterestedPositions: [],
      filteredRecentPositions: [],
      filteredPreviousPositions: [],
      typesFilterSelections: [],
      select: {
        jobId: '',
        range: null,
        clientId: '',
        type: null,
        interestedPositions: null,
        previousPositions: null,
        recentPositions: null,
      },
      switches: {
        interestedPositionsDefaultOr: true,
        recentPositionsDefaultOr: true,
        previousPositionsDefaultOr: true,
      },
      eventModalDisplayFields: {
        jobTitle: '',
        shifterNames: [],
      },
      eventForm: {
        title: '',
        job: '',
        range: 0,
        shifters: [],
        status: 'inactive',
      },
    };
  },
};
</script>
<style></style>
